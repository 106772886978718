.arbMiner{
  .logo{
    display: flex;
    justify-content: center;
    width: 250px;
    margin: 30px auto;
  }
  .slogan{
    font-size: 25px;
    line-height: 30px;
    color: #2D374B;
    font-weight: bold;
    text-align: center;
  }
  .buttonGroup{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  .whitepaper{
    //display: block;
    margin: auto;
    width: 180px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 10px;
    border: 2px solid #FFFFFF;
    background-color: #BADEF8;
    padding: 5px 0;
    box-shadow: 0 3px #FFFFFF;
    color: black;
    a{
      color: white;
    }

    &:hover{
      cursor: pointer;
    }
    &:active {
      background-color: #BADEF8;
      box-shadow: 0 3px #BADEF8;
      transform: translateY(2px);
    }
  }

  .countdownWrapper{
    color: #2D374B !important;
    .Countdown, .Countdown-col, .Countdown-col-element{
      color: #2D374B !important;
    }
  }

  .sloganWrapper{
    display: flex;
    justify-content: space-between;
    width: 800px;
    max-width: 100%;
    gap: 10px 10px;
    margin: 20px auto 0 auto;
    position: relative;
    flex-wrap: wrap;
    .sloganItem{
      width: calc(50% - 10px);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #BADEF8;
      border: 1px solid #FFFFFF;
      border-radius: 20px;
      padding: 15px;
      color: #2D374B;
      font-weight: 700;
      img{
        width: 50px;
        margin-right: 20px;
      }
    }
  }
  .mainContent{

    width: 800px;
    max-width: 100%;
    margin: 20px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    .box{
      width: calc(50% - 10px);
    }
    .leftBox{
      padding: 20px;
      background: #EFEFEF;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
    }
    .rightBox{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .dataRow{
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin: 5px 0;
      color: #2D374B;
      font-size: 18px;
      line-height: 35px;
      .name{
        opacity: 0.5;
      }
      .value{
        font-weight: 600;
        font-size: 22px;
        color: #27A0F0;
      }

    }

    .ant-input-affix-wrapper {
      height: 55px;
      margin: 0;
      position: relative;
      box-shadow: none;
      backdrop-filter: blur(20px);
      box-sizing: border-box;
      border-radius: 40px;
      background: #27A0F0;
      border: 2px solid #2D374B;
      .ant-input-suffix,
      .ant-input-prefix {
        color: white;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
        span {
          color: white;
        }
      }
      .suffix {
        padding-right: 10px;
      }

      .ant-input {
        border: none;
        color: white;
        background: transparent;
        font-size: 16px;
        font-weight: 600;
        line-height: 55px;
        text-align: center;
        padding-left: 25px;
      }

      .ant-input:hover {
        border: none !important;
      }
      .ant-input:focus {
        border: none !important;
        outline: 0;
        box-shadow: none !important;
      }
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
      border-color: #2D374B !important;
      border-right-width: 2px;
    }

    .ant-input-affix-wrapper-focused {
      outline: 0;
      background: #27A0F0 !important;
      border: 2px solid #2D374B !important;
    }

    .buyButton{
      border: 2px solid #2D374B;
      border-radius: 40px;
      color: #2D374B;
      font-size: 16px;
      font-weight: 600;
      line-height: 42px;
      padding: 5px 0;
      text-align: center;
      margin-top: 10px;
      background: #EFEFEF;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
      &:hover{
        cursor: pointer;
        border: 2px solid white;
        color: white;
        background: #27A0F0;
      }
    }

    .actionWrapper{
      margin-top: 30px;
      border-top: 1px dashed white;
      padding-top: 10px;

      .actionButtons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        div{
          width: calc(50% - 10px);
          text-align: center;
          border: 2px solid #2D374B;
          color: #2D374B;
          font-weight: bold;
          font-size: 16px;
          padding: 8px 0;
          border-radius: 30px;
          background: #EFEFEF;
          box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
          &:hover{
            cursor: pointer;
            border: 2px solid white;
            color: white;
            background: #27A0F0;
          }
        }
      }
    }

    .contractInfo{
      background: #2D374B;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        width: 25%;
      }

      .data{
        width: 65%;

        h1{
          color:white;
          font-size: 25px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .dataRow{
          font-size: 16px;
          color:white;
          .name{
            opacity: 1;
          }
          .value{
            color:white;
          }
        }
      }

    }

    .referral{
      background: #EFEFEF;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: 20px;
      margin-top: 20px;
      h1{
        color:black;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      p{
        color:black;
      }
      .refWrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .referralLink{
          border: 2px solid black;
          box-sizing: border-box;
          border-radius: 40px;
          padding: 5px 20px;
          color: black;
          flex-grow: 2;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 70%;
        }
        .copyButton{
          border: 2px solid black;
          box-sizing: border-box;
          border-radius: 40px;
          padding: 5px 20px;
          color: black;
          background-color: transparent;
          &:hover{
            cursor: pointer;
            border: 2px solid #FFFFFF;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  @media(max-width: 765px){
    .logo{
        width: 200px;
        margin: 0 auto;
      }
    .slogan{
      line-height: 20px;
    }
    .buttonGroup{
      display: flex;
      //flex-direction: column;
    }

    .sloganWrapper{
      gap: 5px;
      .sloganItem{
        width: calc(100% - 20px);
        margin: auto;
        padding: 10px;
        img{
          width: 30px;
        }
      }
    }
    .mainContent{
      margin: 30px auto 0 auto;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      .box{
        width: calc(100% - 20px);
      }

      .referral{
        margin-top: 10px;
      }
    }
  }
}

.blink_me {
  animation: blinker 2s linear 1;
}

@keyframes blinker {
  50% { color: white; transform: scale(1.1) }
}