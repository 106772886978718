.info{

  .iuoInfo, .tokenInfo{
    position: relative;
    padding: 50px 40px;
    background: #1A253C;
    border: 4px solid #ffffff33;
    border-radius: 20px;
    height: 343px;
    h2{
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
      text-align: center;
      z-index: 2;
      color:#EFEFEF;
      opacity: 0.5;
    }
    p{
      position: relative;
      z-index: 2;
      margin: 10px 0;
      color: rgba(239, 239, 239, 0.6);
      display: flex;
      justify-content: space-between;
      span{
        color: white;
        font-weight: 600;
      }
    }
    .addToMetaMask{
      justify-content: center;
      font-size: 16px;
      text-align: center;
      color: #27A0F0;
      cursor: pointer;
      font-weight: bold;
    }

    .addToMetaMask:hover{
      opacity: 0.8;
    }

    .img{
      width: 30%;
      position: absolute;
      bottom: 0;
      z-index: 1;
      right: 0;
      opacity: 0.5;
    }
  }

  @media(max-width:765px){
    .iuoInfo, .tokenInfo{
      padding: 20px;
      border-radius: 5px;
      height: auto;
      margin-bottom: 16px;
    }
  }
}
