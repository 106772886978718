.myPortfolio{

  .darkBox{
    padding: 40px 40px;
    background: #1A253C;
    border: 4px solid #ffffff33;
    border-radius: 20px;
    h2{
      font-size: 25px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 10px;
      color:#EFEFEF;
      opacity: 0.5;
    }

    p{
      margin: 10px 0;
      color: rgba(255,255,255,0.60);
      display: flex;
      justify-content: space-between;
      .floatRight{
        color: white;
        font-weight: 600;
        strong{
          font-weight: 600;
        }
      }
    }

    .button{
      padding: 10px 0;
      background: #27A0F0;
      width: 200px;
      font-weight: bold;
      border-radius: 20px;
      color: white;
    }
  }

  @media(max-width:765px){
    .darkBox{
      padding: 20px;

      h2{
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
      }

      p{
        margin: 10px 0;

      }
    }
  }

}
