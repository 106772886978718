.header{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #2D374B;
  .walletWrapper{
    user-select: none;
    width: 200px;
    color: white;
    font-size: 16px;
    padding: 5px 30px;
    border: 1px solid white;
    border-radius: 20px;
    text-align: center;
    font-weight: bold;
    &:hover{
      cursor: pointer;
      color: white;
      background-color: #27A0F0;
    }
  }

  .tabs{
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;

    div{
      width: 120px;
      font-weight: 600;
      font-size: 13px;
      padding: 10px 0;
      border: 1px solid white;
      box-sizing: border-box;
      border-radius: 10px;
      text-align: center;
      color: white;
      &:hover{
        cursor: pointer;
        color: white;
        border-color: #27A0F0;
      }
    }

    .active{
      //background-image: url("../../assets/butonBg.gif");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      color: #27A0F0;
      border-color: #27A0F0;
    }

    .bnbMiner{
      font-weight: bold;
      color: #F3BA2F;
      border-color: #F3BA2F;
      &:hover{
        cursor: pointer;
        color: #F3BA2F;
        border-color: #F3BA2F;
      }
    }

    .airdrop{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 20px;
      }
    }
  }

  .socialMedias{
    width: auto;
    text-align: right;
    a{
      margin: 0 15px;
    }
    img{
      width: 27px;
    }
  }
}

@media(max-width: 765px) {
   .header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      //flex-direction: column;
      padding: 20px 0 0 0;
      flex-wrap: wrap;
      position: relative;
      width: 100%;
      .walletWrapper{
        margin-right: auto;
        width: 120px;
        font-size: 13px;
        padding: 5px 0;
        margin-left: 20px;
      }

      .tabs{
        order:3;
        position: relative;
        transform: translate(0,0);
        left: initial;
        margin: 30px auto 10px;
        gap: 10px;
        flex-wrap: wrap;
        width: calc(100vw - 40px);
        div{
          width: calc(33vw - 30px);
          font-weight: 600;
          font-size: 10px;
          padding: 5px 10px;
          box-sizing: border-box;
          border-radius: 10px;
          text-align: center;
          &:hover{
            cursor: pointer;
          }
        }
        .airdrop{
          img{
            width: 20px;
          }
        }

        .active{
          //background-image: url("../../assets/butonBg.gif");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .socialMedias{
        order:2;
        width: 50%;
        text-align: center;
        //margin: 20px auto 0;
        a{
          margin: 0 10px;
        }
        img{
          width: 25px;
        }
      }
    }
}

