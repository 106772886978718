.iuo{
  margin: 50px 0;
  position: relative;
  // min-height: 110vh;
  .projectWrap{
    padding: 50px;
    background: #1A253C;
    border: 4px solid #ffffff33;
    border-radius: 20px;
    position: relative;
    .project{
      position: relative;
      .intro{
        position: relative;
        img{
          width: 80px;
          height: 80px;
          background-color: white;
          padding: 5px;
          border-radius: 40px;
          position: absolute;
          top: 50%;
          left:0;
          transform: translateY(-50%);
        }

        .title{
          position: relative;
          left: 100px;
          width: calc(100% - 100px);

          h1{
            font-size: 30px;
            font-weight: 600;
            color: #27A0F0;
            span{
              color: white;
            }
          }
          p{
            position: relative;
            color: #27A0F0;
            text-decoration: underline;
          }
        }
      }

      .progress{
        margin-top: 30px;
        p{
          font-weight: 600;
          margin: 5px 0;
          span{
            float:right;
            span{
              color: #27A0F0;
              float: none;
            }
          }
        }
      }
    }
    color: white;

    .participateWrap{
      h3{
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;
        color: white;
      }
      p{
        text-align: right;
        margin: 5px 0;
        color: white;
        span{
          color: #27A0F0;
          padding-right: 5px;
        }
      }

      .button{
        width: 80%;
        margin: 0 auto;
        background: #27A0F0;
        padding: 10px 0;
        border-radius: 20px;
        text-align: center;
        font-size: 15px;
        font-weight: 600;


      }

      .ant-input-affix-wrapper{
        margin: 0;
        position: relative;
        background: transparent;
        border: 2px solid #FFFFFF;
        border-radius: 10px;
        //border: none;

        .suffix,{
          color: white;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          user-select: none;
        }
        .suffix{
          padding-right: 10px;

          .button{
            font-size: 12px;
            font-weight: normal;
            padding: 5px 10px;
            border-radius: 10px;
            color: white;
            background: #27A0F0;
            vertical-align: middle;
            margin-left: 10px;
          }
        }


        .ant-input{
          border:none;
          background: transparent;
          padding-left: 10px;
          padding-right: 0;
          color: white;
          font-size: 18px;
          font-weight: 500;
          line-height: 40px;
          text-align: left;
        }

        .ant-input:hover {
          border: none !important;
        }

        .ant-input:focus {
          border: none !important;
          outline: 0;
          box-shadow: none !important;
        }
      }

      .ant-input-affix-wrapper-focused{
        outline: 0;
        //border-right-width: 0 !important;
        box-shadow: none !important;
      }
    }
  }

  .tabs{
    position: relative;
    margin: 50px;

    div{
      display: inline-block;
      width: 200px;
      max-width: 33.33%;
      font-size: 20px;
      font-weight: 600;
      color: white;
      cursor: pointer;
    }

    .selectedTab{
      color: #27A0F0;
      text-decoration: underline;
      text-underline-offset: 10px;
    }
  }

  @media(max-width: 765px){
    margin: 20px 0;
    .projectWrap{
      padding: 20px;
      .project{
        .intro{
          img{
            width: 60px;
            height: 60px;
            border-radius: 30px;
          }

          .title{
            position: relative;
            left: 75px;
            width: calc(100% - 75px);

            h1{
              font-size: 20px;
            }
          }
        }

        .progress{
          margin: 30px 0;
          padding-bottom: 30px;

          border-bottom: 1px solid #80808057;
          p{
            font-size: 12px;
          }
        }
      }

      .participateWrap{
        h3{
          text-align: center;
        }

        .button{
          width: 80%;
          padding: 5px 0;
          font-size: 15px;
          font-weight: 500;
          border-radius: 5px;
          strong{
            font-size: 15px;
          }
        }

        .ant-input-affix-wrapper{
          margin: 0;
          position: relative;
          background: #EBEBEB;
          border-radius: 10px;
          border: none;

          .suffix,{
            font-size: 14px;
          }

          .ant-input{
            font-size: 14px;
          }

        }
      }
    }

    .tabs{
      position: relative;
      margin: 40px 0 20px 0;

      div{
        display: inline-block;
        width: 50%;
        max-width: 50%;
        font-size: 16px;
      }
    }
  }
}
