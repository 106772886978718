@import '~antd/dist/antd.css';
.App{
  //background-image: url("./assets/butonBg.gif");
  min-height: 100vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  padding-bottom: 50px;

  .container{
    width: 1000px;
    max-width: 100%;
    padding: 0 10px;
    margin: 0 auto;
    position: relative;
    .countdownWrapper{
      color: #FEBF33;
      text-align: center;
      font-size: 16px;
      margin-top: 10px;
      p{
        opacity: 1 !important;
      }
      .Countdown{
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .Countdown-col{
          display: flex;
          flex-direction: row;
          color: #FEBF33;
          justify-content: center;
          align-items: center;
          font-size: 25px;
        }
        .Countdown-col-element{
          color: #FEBF33;
          font-size: 30px;
        }
      }

    }

  }
}
.customModal {
  &.ant-modal-wrap {
    // left: 200px;
  }
  .ant-modal-content {
    overflow-y: scroll;
    // max-height: 70vh;
    min-height: 300px;
    border-radius: 20px;
    // box-shadow: 0px 0px 10px rgba(171, 238, 247, 0.6), inset 0px 0px 10px #186065;
    width: 350px;
    max-width: 100%;
    margin: 0 auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    border: 2px solid #E58F0E;
    background-color: #C6660D;
    &::-webkit-scrollbar {
      display: none;
    }

    .ant-modal-body {
      padding: 10px 0;

      .close {
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 3;
        cursor: pointer;
        width: 25px;
        opacity: 0.9;
        font-size: 20px;
        color: white;
      }
      .title {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 10px;
        color: white;
        font-size: 20px;
        font-weight: 600;
        left: 50%;
        transform: translateX(-50%);
      }
      .modalHeader {
        width: calc(100% - 20px);
        margin: 10px 10px;
      }
    }
  }
  .ant-modal-close-x {
    color: white;
    display: none;
  }
}

.showSwapModal{
  text-align: center;
  .content{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-size: 20px;
    color: #FEBF33;
    h1{
      font-size: 25px;
      font-weight: bold;
      color: #FEBF33;
      margin-bottom: 0;
    }

    p{
      color: #FEBF33;
    }

    .button{
      font-size: 14px;
      font-weight: 600;
      color: #FEBF33;
      padding: 10px 0;
      border-radius: 20px;
      width: 150px;
      margin: 30px auto 0;
      //background-image: url("./assets/butonBg.gif");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}